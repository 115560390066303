<template>
  <button
    type="button"
    class="primary-btn"
    :class="cssClasses"
    :ontouchstart="isCountBtn"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    isCountBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssClasses() {
      return {
        'primary-btn--blue': this.color === 'blue',
        'primary-btn--white': this.color === 'white',
        'primary-btn--max': this.size === 'max',
        'primary-btn--medium': this.size === 'm',
        'primary-btn--small': this.size === 'sm',
        'primary-btn--sm-md': this.size === 'sm-md',
        'primary-btn--medium-big': this.size === 'md-bg',
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.primary-btn
  border-radius: var(--primary-border-radius)
  cursor: pointer
  height: 64px
  font-weight: 600
  font-size: 18px
  text-align: center
  text-transform: uppercase
  display: flex
  align-items: center
  justify-content: center
  transition: var(--transition-speed)

.primary-btn--blue
  background: var(--btn-color-blue)
  border: 0
  color: var(--primary-color-white)

.primary-btn--white
  background-color: var(--primary-color-white)
  border: 1px solid var(--primary-color-dark-grey-3)
  color: var(--primary-color-dark-blue)

.primary-btn--max
  width: 100%

.primary-btn--sm-md
  width: 200px

.primary-btn--medium
  width: 220px

.primary-btn--medium-big
  width: 260px

.primary-btn--small
  width: 64px

.primary-btn--blue:hover
  background-color: var(--btn-color-blue-hover)

.primary-btn--blue:active
  background-color: var(--btn-color-blue-focus)

.primary-btn--blue[disabled]
  background-color: rgba(148, 157, 172, 0.5)
  cursor: default

.primary-btn--white:hover
  border: 1px solid var(--btn-color-blue)

.primary-btn--white:active
  border: 1px solid rgba(48, 83, 240, 0.8)

.primary-btn--white[disabled]
  opacity: 0.5
  cursor: default
  border: 1px solid var(--primary-color-dark-grey-1)
  color: var(--primary-color-dark-grey-1)
</style>
