<template>
  <div class="barriers">
    <template v-if="barriers">
      <div v-for="item in barriers" :key="item.uuid" class="barriers__item">
        <Heading level="4"> {{ item.title }} </Heading>
        <ButtonBase
          class="barrier__btn"
          size="m"
          color="white"
          :disabled="raisedBarriers.includes(item.uuid)"
          @click.native="raiseBarrierHandler(item)"
        >
          <svg-icon name="open-barrier"></svg-icon>
          <span class="barrier__btn-text">
            {{ $t(`barrier.open_barrier`) }}
          </span>
        </ButtonBase>
      </div>
    </template>
    <Loader v-else />
  </div>
</template>

<script>
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Heading from '@/elements/Heading/Heading.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: { Heading, ButtonBase, Loader },
  data() {
    return {
      barriers: null,
      raisedBarriers: [],
    };
  },
  mounted() {
    this.fetchBarriers();
  },
  methods: {
    async fetchBarriers() {
      const res = await this.$store.dispatch('getBarriers');
      this.barriers = res;
    },
    async raiseBarrierHandler(item) {
      this.raisedBarriers.push(item.uuid);

      await this.$store.dispatch('raiseBarrier', item.uuid);
      this.$store.commit('setNotification', {
        type: 'success',
        title: `${item.title}`,
        text: 'Шлагбаум успішно відчинений',
      });

      setTimeout(() => {
        this.raisedBarriers.shift();
      }, 10000);
    },
  },
};
</script>
<style lang="sass" scoped>
.barriers__item+.barriers__item
  margin-top: 32px
  border-top: 1px solid var(--primary-color-light-grey-2)

.barrier__btn
  justify-content: flex-start!important
  align-items: center!important
  padding-left: 25px
  text-transform: unset!important
  box-shadow: 0px 32px 80px rgba(38, 55, 76, 0.05)
  font-size: 24px!important
  font-weight: 600!important
  height: 100px!important

.barrier__btn,
.barrier__btn:hover
  border-color: var(--primary-color-success)!important
  color: var(--primary-color-success)!important

.barrier__btn.active
  background-color: rgba(9, 163, 86, 0.04)!important

.barrier__btn[disabled],
.barrier__btn[disabled],
.barrier__btn[disabled]:hover,
.barrier__btn[disabled]:hover
  border-color: var(--primary-color-dark-grey-1)!important

.icon-open-barrier
  height: 35px
  width: 32px

.barrier__btn-text
  padding-left: 12px
</style>
